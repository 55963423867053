<div class="holder">
  <div class="text-container mat-elevation-z8 ">
    <p>Välkommen till tjänsten för verifiering av digitalt ID-kort. För att
      kunna använda tjänsten behöver du först
      identifiera dig med BankID.</p>
    <spb-bankid (accessToken)="configService.handleAuthentication($event)"
                [bankIdUrl]="bankIdUrl" [domain]="domain"
                [mode]="mode"></spb-bankid>
  </div>
</div>


@if (configService.permissionDenied$()) {
  <div class="error">Du saknar behörighet för att använda denna sida</div>
}
