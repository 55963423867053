import {DatePipe, SlicePipe} from '@angular/common'
import {AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core'
import {MatFormField, MatLabel} from '@angular/material/form-field'
import {MatInput} from '@angular/material/input'
import {BankIDVerificationItem} from 'sparbanken-syd-bil'
import {DataService} from '../services/data.service'
import {MatSort} from '@angular/material/sort'
import {FormControl, ReactiveFormsModule} from '@angular/forms'
import {startWith} from 'rxjs'
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell,
  MatHeaderCellDef,
  MatHeaderRow,
  MatHeaderRowDef,
  MatNoDataRow,
  MatRow,
  MatRowDef,
  MatTable,
  MatTableDataSource
} from '@angular/material/table'
import {MatDialog} from '@angular/material/dialog'
import {IdListDialogComponent} from '../common/id-list-dialog/id-list-dialog.component'
import '@angular/common/locales/global/fr'

@Component({
  selector: 'spb-id-list',
  templateUrl: './id-list.component.html',
  styleUrls: ['./id-list.component.scss'],
  imports: [
    MatHeaderCellDef,
    SlicePipe,
    MatTable,
    ReactiveFormsModule,
    MatFormField,
    DatePipe,
    MatColumnDef,
    MatHeaderCell,
    MatSort,
    MatInput,
    MatCell,
    MatCellDef,
    MatHeaderRowDef,
    MatRowDef,
    MatNoDataRow,
    MatRow,
    MatHeaderRow,
    MatLabel
  ]
})
export class IdListComponent implements OnInit, AfterViewInit {
  @Output() handleSwipeLeft = new EventEmitter<Event>()
  @Output() handleSwipeRight = new EventEmitter<Event>()
  @ViewChild(MatSort) daSort = new MatSort()

  public columnsToDisplay: string[] = ['timeStamp', 'personalNumber', 'name']

  public filterControl = new FormControl<string>('', {nonNullable: true})

  public dataSource: MatTableDataSource<BankIDVerificationItem> = new MatTableDataSource<BankIDVerificationItem>()

  constructor(
    private dataService: DataService,
    private dialog: MatDialog
  ) {
  }

  public ngOnInit(): void {

    /**
     * The first properties of each object must consist of the items listed. (should be improved...)
     */
    this.dataSource.data = this.dataService.bankIDVerificationItem()
      .sort((a: any, b: any) => b.timeStamp - a.timeStamp)
      .map((item: any) => {
        return {
          ...item,
          name: item.user?.name,
          personalNumber: item.user?.personalNumber
        }
      })

    /**
     * Controls the filter input field, replace is for sub
     */
    this.filterControl.valueChanges.pipe(
      startWith('')
    ).subscribe({
      next: (value: string) =>
        this.dataSource.filter = value.trim().toLowerCase().replace('-', '')
    })

    this.dataSource.sort = this.daSort
  }

  public ngAfterViewInit(): void {
    this.dataSource.sort = this.daSort
  }

  public openDialog(bid: BankIDVerificationItem) {
    this.dataService.userData.set({...bid, title: 'Detaljer'})
    this.dialog.open(IdListDialogComponent, {
        width: `300px`
      }
    )
  }
}
