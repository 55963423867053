import {Platform} from '@angular/cdk/platform'
import {AsyncPipe} from '@angular/common'
import {AfterViewInit, Component, Inject, inject} from '@angular/core'
import {MatButton} from '@angular/material/button'
import {MatDialog} from '@angular/material/dialog'
import {MatIcon} from '@angular/material/icon'
import {MatMenu, MatMenuItem, MatMenuTrigger} from '@angular/material/menu'
import {Router} from '@angular/router'
import {LogoComponent} from '@sparbanken-syd/sparbanken-syd-theme'
import {environment} from '../../../environments/environment'
import {WINDOW} from '../../application/app'
import {ConfigService} from '../../services/config.service'
import {
  InstallInstructionsDialogComponent
} from '../install-instructions-dialog/install-instructions-dialog.component'

@Component({
  selector: 'spb-header',
  templateUrl: './header.component.html',
  imports: [
    AsyncPipe,
    MatMenuTrigger,
    MatIcon,
    MatMenu,
    MatMenuItem,
    MatButton,
    LogoComponent
  ],
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements AfterViewInit {

  public configService = inject(ConfigService)

  /**
   * If the user can install the app
   */
  public canInstall = false

  private platform = inject(Platform)

  private matDialog = inject(MatDialog)

  private router = inject(Router)
  private deferredPrompt: any

  constructor(@Inject(WINDOW) private injectedWindow: Window) {
  }

  public install(): void {
    console.log('install')
    if (this.platform.IOS) {
      this.matDialog.open(InstallInstructionsDialogComponent, {
        width: '100%',
        position: {top: '0'}
      })
    }
    // Show the install prompt
    this.deferredPrompt.prompt()
    // We've used the prompt, and can't use it again, throw it away
    this.deferredPrompt = null
  }

  public goToAdmin(): void {
    this.router.navigate([environment.adminLink]).then(() => {
      window.open(environment.adminLink, '_blank')
    })
  }


  public ngAfterViewInit() {
    console.log('hej')
    // Initialize deferredPrompt for use later to show browser install prompt.
    this.injectedWindow.addEventListener('beforeinstallprompt', (event: Event) => {
      console.log(event)
      // Stash the event so it can be triggered later.
      this.deferredPrompt = event
      // Show the install button in the menu
      this.canInstall = true
    })
  }
}
