import {Component, EventEmitter, Output} from '@angular/core'

@Component({
    selector: 'spb-welcome',
    templateUrl: './welcome.component.html',
    styleUrls: ['./welcome.component.scss'],
})
export class WelcomeComponent {
  @Output() handleSwipeLeft = new EventEmitter<Event>()
  @Output() handleSwipeRight = new EventEmitter<Event>()
}
