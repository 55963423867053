import {Routes} from '@angular/router'
import {StartComponent} from './1-start/start.component'
import {authGuard} from './application/auth.guard'
import {LOGIN_ROUTE_PATH} from './application/data-types'
import {LoginComponent} from './login/login.component'

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: LOGIN_ROUTE_PATH
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'start',
    component: StartComponent,
    canActivate: [authGuard]
  },
  {
    path: '**',
    redirectTo: LOGIN_ROUTE_PATH
  }
]
