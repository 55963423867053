import {Component, EventEmitter, Output} from '@angular/core'

@Component({
    selector: 'spb-help',
    templateUrl: './help.component.html',
    styleUrls: ['./help.component.scss'],
})
export class HelpComponent {
  @Output() handleSwipeRight = new EventEmitter<Event>()
}
