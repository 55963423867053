import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  NgZone,
  OnDestroy,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core'
import {FormControl, ReactiveFormsModule} from '@angular/forms'
import {MatDialog, MatDialogRef} from '@angular/material/dialog'
import {MatFormField, MatLabel} from '@angular/material/form-field'
import {MatOption, MatSelect} from '@angular/material/select'
import QrScanner from 'qr-scanner'
import {EMPTY, Subscription} from 'rxjs'
import {catchError, filter} from 'rxjs/operators'
import {
  BankIDVerificationError,
  BankIDVerificationItem
} from 'sparbanken-syd-bil'
import {LOCAL_STORAGE} from '../application/app'
import {
  IQrScannerFactory,
  QR_SCANNER_FACTORY
} from '../application/qr-scanner.provider'
import {
  IdListDialogComponent
} from '../common/id-list-dialog/id-list-dialog.component'
import {WaitDialogComponent} from '../common/wait-dialog/wait-dialog.component'
import {ConfigService, ILogInState} from '../services/config.service'
import {DataService} from '../services/data.service'
import Camera = QrScanner.Camera
import ScanResult = QrScanner.ScanResult

@Component({
  selector: 'spb-scanner',
  templateUrl: './scanner.component.html',
  styleUrls: ['./scanner.component.scss'],
  imports: [
    MatFormField,
    MatSelect,
    MatOption,
    ReactiveFormsModule,
    MatLabel
  ]
})
export class ScannerComponent implements AfterViewInit, OnDestroy {
  @Output() handleSwipeLeft = new EventEmitter<Event>()
  @Output() handleSwipeRight = new EventEmitter<Event>()
  @ViewChild('video', {static: false}) video: ElementRef = new ElementRef(undefined)

  public haveFlash = false
  public cameraList: Camera[] = []
  public selectedCamera: FormControl = new FormControl<string>('')
  public analyzeDialogRef: MatDialogRef<WaitDialogComponent> = {} as any
  public counter = 0

  private qrScanner: QrScanner | undefined
  private userSub: Subscription = new Subscription()

  constructor(
    @Inject(QR_SCANNER_FACTORY) private qrFactory: IQrScannerFactory,
    @Inject(LOCAL_STORAGE) private injectedLocalStorage: Storage,
    private dataService: DataService,
    private matDialog: MatDialog,
    private ngZone: NgZone,
    private renderer: Renderer2,
    private configService: ConfigService
  ) {
  }

  public ngAfterViewInit() {
    this.selectedCamera.valueChanges.subscribe({
      next: (cameraId: string) => {
        this.changeCamera(cameraId)
      }
    })
    const savedCamera: string | null = this.injectedLocalStorage.getItem('camera')
    this.selectedCamera.setValue(savedCamera)
    this.userSub = this.configService.logInState$.pipe(
      filter((user: ILogInState | null) => user?.name === '')
    ).subscribe({
      next: () => {
        this.stop()
      }
    })
    this.qrScanner = this.qrFactory.getScanner(
      this.video.nativeElement,
      (result: ScanResult) => {

        /**
         * Validates the scanned QR code before processing further actions.
         * If the QR code passes the check, it proceeds with identification,
         * opens a waiting dialog, and stops further scanning.
         */
        if (this.checkScan(result.data)) {
          this.stop()
          this.identify(result.data)
          this.analyzeDialogRef = this.matDialog.open(WaitDialogComponent, {
            width: '300px'
          })
        }
      },
      {
        returnDetailedScanResult: true,
        preferredCamera: savedCamera,
        maxScansPerSecond: 10
      }
    )

    setTimeout(() => {
      this.renderer.setStyle(this.video.nativeElement, 'opacity', 1)
      this.renderer.setStyle(this.video.nativeElement, 'height', '100%')
      this.renderer.setStyle(this.video.nativeElement, 'width', '100%')
      this.renderer.setStyle(this.video.nativeElement, 'visibility', 'visible')
      this.renderer.setStyle(this.video.nativeElement, 'clip-path', 'inset(30px 0)')
    }, 1)
    this.start()
  }

  public start(): void {
    if (this.qrScanner) {
      this.counter = 0
      this.qrScanner.start().then(() => {
        this.qrFactory.listCameras(true).then((cameras: Camera[]) => this.cameraList = cameras)
        this.checkFlash()
      })
    }
  }

  public stop(): void {
    if (this.qrScanner) {
      this.qrScanner.stop()
    }
  }

  public flash() {
    if (this.qrScanner) {
      this.qrScanner.toggleFlash().then()
    }
  }

  public changeCamera(cameraId: string): void {
    if (this.qrScanner) {
      this.qrScanner.setCamera(cameraId).then()
      this.injectedLocalStorage.setItem('camera', cameraId)
    }
  }

  ngOnDestroy(): void {
    this.userSub.unsubscribe()
  }

  /**
   * Return true if the QR code matches the expected format from BankID or if the counter is greater than 1.
   * We should not scan 'vådaskott,' as it is not a QR code. For instance, a shoe is not a valid QR code
   * @param scanData
   * @private
   */
  private checkScan(scanData: string): boolean {
    this.counter++
    const splitData: string[] = scanData.split('.')
    const conditionList: boolean[] = []
    conditionList.push(splitData[0] === 'BANKIDF')
    if (splitData.length === 4) {
      conditionList.push(splitData[1].length === 32)
      conditionList.push(typeof +splitData[2] === 'number')
      conditionList.push(splitData[3].length === 64)
    }
    return conditionList.reduce((acc: boolean, curr: boolean) => acc === curr, true) || (this.counter > 1)
  }


  private identify(scanResult: string): void {
    this.dataService.checkId(scanResult).pipe(
      catchError((err: { status: number }) => {

        if (err.status === 400) {
          /**
           * Close analyze ref
           */
          this.analyzeDialogRef.close()
          this.dataService.userData.set({
            timeStamp: Date.now(),
            errorCode: 'Felaktig QR kod',
            details: 'QR-koden är ogiltig',
            title: 'Ej legitimerad'
          } as BankIDVerificationError)
        }

        this.openDialog()
        return EMPTY
      }),
      filter(Boolean)
    ).subscribe({
      next: (res: BankIDVerificationItem): void => {
        /**
         * Close analyze ref
         */
        this.analyzeDialogRef.close()

        if ('user' in res) {
          this.dataService.userData.set({...res, title: 'Legitimerad'})
        } else {
          this.dataService.userData.set({...res, title: 'Ej legitimerad'})
        }
        this.dataService.bankIDVerificationItem.update((oldBid: BankIDVerificationItem[]) => [res, ...oldBid])
        this.openDialog()
      }
    })
  }

  private openDialog(): void {
    this.ngZone.run(() => {
      this.matDialog.open(IdListDialogComponent, {
        width: '300px'
      }).afterClosed().pipe(
        filter(Boolean)
      ).subscribe({
        next: () => {
          this.start()
        }
      })
    })
  }

  private checkFlash(): void {
    if (this.qrScanner) {
      this.qrScanner.hasFlash().then((haveFlash: boolean) => this.haveFlash = haveFlash)
    }
  }

}
