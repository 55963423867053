import {HttpEvent, HttpHandlerFn, HttpRequest} from '@angular/common/http'
import {inject} from '@angular/core'
import {first, Observable, switchMap} from 'rxjs'
import {ConfigService} from '../services/config.service'

/**
 * Intercept all requests and add auth token. We should however
 * do this only on white listed requests so that we do not
 * send our tokens when not needed. In its simplest form this
 * assumes that all outgoing requests after the token is set
 * should have it.
 */
export const authInterceptor = (req: HttpRequest<any>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
  const configService = inject(ConfigService)
  return configService.accessToken$.pipe(
    first(),
    switchMap(token => {
      // Check if a token is available and if the URL contains 'user'
      if (!token || req.url.indexOf('user') === -1) {
        return next(req) // Pass the request without modifications
      }

      // If the URL contains 'user', add the Authorization header
      const authReq = req.clone({
        headers: req.headers.set('Authorization', `Bearer ${token}`)
      })
      return next(authReq) // Pass the modified request
    })
  )
}